<template>
    <el-container>
        <el-aside v-if="dialogs.length > 0">
            <span>更新日志</span>
            <ul>
                <li v-for="item of dialogs" :key="item.id" :class="{ active: flag === item.id }" @click="logUpdateDetails(item)">{{ item.createTime }}&nbsp;更新日志</li>
            </ul>
        </el-aside>
        <el-main class="box-card" style="background: #fff">
            <div class="log-content">
                <div class="title">{{ content.createTime.split(' ')[0] }} 更新日志</div>
                <el-row type="flex" justify="space-between" class="desc">
                    <span class="time">更新时间：{{ content.createTime }}</span>
                    <span class="version" v-if="content.newVersion">版本号：{{ content.newVersion }}</span>
                </el-row>
                <el-row type="flex" justify="center">
                    <div v-html="content.updateDescription"></div>
                </el-row>
            </div>
            <!-- <TableEmpty v-else /> -->
        </el-main>
    </el-container>
</template>

<script>
import { logUpdateDetails, timeList } from '@/api/updateLog'
export default {
    data() {
        return {
            dialogs: [],
            flag: '',
            createTime: '',
            content: ''
        }
    },
    mounted() {
        this.timeList()
    },
    methods: {
        async timeList() {
            const result = await timeList({
                appType: 5
            })
            if (result.state === 0) {
                this.dialogs = result.data
                this.logUpdateDetails(this.dialogs[0])
            }
        },
        async logUpdateDetails(item) {
            this.createTime = item.createTime
            this.flag = item.id
            const result = await logUpdateDetails({ id: item.id })
            if (result.state === 0) {
                this.content = result.data
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.el-aside {
    min-width: 230px;
    max-width: 230px;
    padding: 8px 0;
    background-color: #fff;
    height: 100%;
    ul {
        padding-left: 0;
        li {
            padding-left: 35px;
            list-style: none;
            display: block;
            padding: 10px 30px;
            cursor: pointer;
            a {
                text-decoration: none;
                color: #666666;
            }
            &:hover {
                background-color: #efefef;
            }
        }
        .active {
            color: #3fcf89;
        }
    }
    span {
        display: block;
        font-size: 20px;
        font-weight: 600;
        padding-left: 10px;
    }
}
.box-card {
    background-color: #fff;
    margin-left: 5px;
    .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        text-align: center;
    }
    .desc {
        margin: 20px 0;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
    }
    .tips {
        margin: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #409eff;
        line-height: 22px;
    }
    .tc {
        text-align: center;
    }
    .btns-wrap {
        text-align: center;
        margin-top: 20px;
    }
}
</style>
